<template>
    <div :class="slimVersion ? '' : 'd-flex flex-column'" ref="contentContainerRef">
        <div class="d-flex align-items-center" :class="slimVersion ? '' : 'toast-title justify-content-between w-100'">
            <span class="d-flex" :class="slimVersion ? 'align-items-center' : 'gap-1 p-1'">
                <i :class="[iconClass, slimVersion ? 'fs-6' : 'fs-2']" class="me-1 o365-toast-icon"></i>
                <span v-if="!slimVersion" class="align-self-center fw-bold">{{ title ?? translateType }}</span>
            </span>
        
            <div v-if="slimVersion" class="toast-body">
                {{ message }}
            </div>
        
            <button type="button" class="btn-close me-2" :class="slimVersion ? 'm-auto' : ''" data-bs-dismiss="toast" aria-label="Close"></button>
        
            <span v-if="instance > 1" class="position-absolute badge rounded-pill" style="right: 0.25rem; top: 0.25rem;"
                :class="`bg-${type}`">
                {{ instance }}
            </span>
        </div>

        <div v-if="!slimVersion" class="toast-body overflow-y-auto scrollbar-hidden" style="max-height:250px;">
            {{ message }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import { $t } from "o365-utils";
    import { ref, computed, watch } from "vue";

    const translateType = computed(() => {
        switch (props.type) {
            case "danger":
                return $t('Error');
            case "warning":
                return $t('Warning');
            case "info":
                return $t('Info');
            case "success":
                return $t('Success');
            default:
                return "";
        }
    });

    const props = defineProps<{
        title: string,
        type: string,
        iconClass: string,
        message: string,
        slimVersion: boolean
        instance: number
    }>();

    const contentContainerRef = ref<HTMLElement | null>(null);

    let shakeAnimationDebounce: number | null = null;

    watch(() => props.instance, () => {
        if (shakeAnimationDebounce) { window.clearTimeout(shakeAnimationDebounce); }
        contentContainerRef.value?.parentElement?.parentElement?.classList.add('shake-animation');
        shakeAnimationDebounce = window.setTimeout(() => {
            contentContainerRef.value?.parentElement?.parentElement?.classList.remove('shake-animation');
            shakeAnimationDebounce = null;
        }, 500);
    });
</script>

<style scoped>
    .toast-body {
        white-space: pre-line;
    }

    .scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge add Firefox */
    .scrollbar-hidden {
        -ms-overflow-style: none;
        scrollbar-width: none;
        /* Firefox */
    }
</style>
